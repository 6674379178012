//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { delLablePerson, documentLablePage, getLablePersonList } from '@/api/modular/fileStream/documentLabelManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { initWx } from '../components/minxin'
export default {
    mixins: [initWx],
    components: {
        OpenData,
    },
    data() {
        return {
            queryParam: {},
            tags: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        onSearch() {
            this.getTags()
        },
        toggle(index, tagIndex) {
            this.$refs[`checkboxes-${tagIndex}-${index}`][0].toggle()
        },
        addLabelName() {
            this.$router.push({ name: 'addLabelName' })
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        getDataKeyAndValue(node) {
            const key = node.deptId ? 'id' : 'userId'
            return {
                [key]: node.deptId || node.userId,
            }
        },
        handleSubmit() {
            let array = []
            this.tags.forEach((j) => {
                if (Array.isArray(j.selectPerson)) array = array.concat([...j.selectPerson])
            })
            const result = [...new Set(array)].map((j) => ({
                corpId: j.corpId,
                ...this.getDataKeyAndValue(j),
                type: j.deptId ? 'departmentName' : 'userName',
            }))
            console.log(result)
            this.$emit('selectPersonsFromLabel', result)
        },
        handleClick(tag) {
            tag.unfold = !tag.unfold
            if (tag.persons === 0) {
                getLablePersonList({
                    labelId: tag.id,
                })
                    .then((res) => {
                        if (res.code === 200) {
                            tag.persons = res.data
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        tag.persons = []
                    })
            }
        },
        toAddPerson(tag) {
            this.$router.push({
                name: 'addLabelPerson',
                query: {
                    tag: encodeURIComponent(JSON.stringify(tag)),
                },
            })
        },
        delPerson(tag, id) {
            delLablePerson([
                {
                    id,
                },
            ])
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        const tagIndex = tag.persons.findIndex((j) => j.id === tag.id)
                        tag.persons.splice(tagIndex, 1)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows.map((j) => ({ ...j, unfold: false, persons: 0, selectPerson: [] }))
                    console.log(this.tags, 'this.tags')
                } else {
                    res.message && this.$message.warning(res.message)
                }
            })
        },
    },
}
