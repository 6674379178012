//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import minxin, { billDetail, initWx } from '../components/minxin'
import baseInfo from './components/baseInfo.vue'
import showOnlyProcess from './components/showOnlyProcess.vue'

export default {
    components: {
        baseInfo,
        showOnlyProcess,
    },
    mixins: [minxin, billDetail, initWx],
    computed: {
        approvalFlag() {
            // let
            const { nodes } = this.myData
            return (
                nodes &&
                nodes.filter((item) => item.sort == 99)[0]?.nodes?.filter((item) => item.userId == userId)[0]
                    ?.approvalFlag
            )
        },
    },
    methods: {
        /**
         * 接收公文
         */
        acceptBill() {
            acceptDocumentBill(this.$route.query.id)
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('接收成功')
                        this.$nextTick(() => {
                            this.$router.push({ name: 'mobileHome', query: { index: 0 } })
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
