//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { acceptAddNextNode, acceptNextNode, carbonCopyBill } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectLabelPer from '../../components/selectLabelPer.vue'
import selectPerson from '../../components/selectPerson'
export default {
    components: {
        OpenData,
        selectPerson,
        selectLabelPer,
    },
    props: {
        billDetailInfo: {
            type: Object,
            default() {
                return {
                    documentNodeList: [],
                    approvalStatus: 0,
                    recipientStatus: 0,
                }
            },
        },
        from: {
            type: String,
            default: '',
        },
    },
    computed: {
        documentNodeListCopy() {
            const documentNodeList = this.finnalyDocumentNodeList.slice()
            const cs = documentNodeList.find((j) => j.sort === 100)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.csr_list = (cs?.nodes || []).map((j) => ({
                corpId: j.corpId,
                [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
                type: this.getDataType(j),
            }))
            console.log(this.csr_list, 'this.csr_list')
            return documentNodeList
        },
        finnalyDocumentNodeList() {
            const documentNodeList = this.billDetailInfo.documentNodeList.slice()
            documentNodeList.forEach((j) => {
                if (![-1].includes(j.sort)) {
                    this.formatNodeMess(j)
                }
            })
            return documentNodeList
        },
        stepIndex() {
            return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
        },
    },
    data() {
        return {
            approvalId: '',
            nodeId: '',
            csr_list: [],
            dealNextLoad: false,
            list: [
                {
                    name: '節點',
                    val: [],
                },
            ],
        }
    },
    methods: {
        choosePerson(list, index) {
            const val = [...this[list]]
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatNodeMess(node) {
            const arr = []
            const getApprovalTime = (deptUsers) => {
                if (deptUsers.some((l) => l.approvalFlag === 0)) return null
                const times = deptUsers.map((user) => {
                    const timeStr = user.approvalTime.replace(/-/g, '/')
                    const time = new Date(timeStr).getTime()
                    return time
                })
                return this.formatApprovalTime(Math.max(times))
            }
            if (node.deptInfos && node.deptInfos.length > 0) {
                node.deptInfos.forEach((dep) => {
                    arr.push({
                        deptId: dep.deptId,
                        deptUsers: dep.deptUsers,
                        corpId: dep.deptUsers[0].corpId,
                        approvalTime: getApprovalTime(dep.deptUsers),
                        status: Number(!dep.deptUsers.some((l) => l.status === 0)),
                        approvalFlag: Number(!dep.deptUsers.some((l) => l.approvalFlag === 0)),
                    })
                })
            }
            let array = []
            array = array.concat(
                [...node.nodes, ...arr].filter((j) => j.approvalFlag === 1),
                [...node.nodes, ...arr].filter((j) => j.approvalFlag !== 1)
            )
            node.nodes = array
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        showSelectDialog(key, index) {
            this.$refs.selectPerson.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.choosePerson(data.key, data.index)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key, index } = data
                if (key === 'list') {
                    result.forEach((ele) => {
                        if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                            this[key][index].val.push(ele)
                        }
                    })
                } else {
                    result.forEach((element) => {
                        if (!this[key].some((j) => this.objectEquail(j, element))) {
                            this[key].push(element)
                        }
                    })
                }
            }
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        carbonCopy({ id }) {
            carbonCopyBill({
                nodeId: id,
                userList: this.csr_list.map(({ userId, corpId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                })),
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('抄送成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatApprovalStatus(approvalFlag, sort, status) {
            if (sort === 99) {
                return approvalFlag ? '已接收' : '待接收'
            }
            if (sort === 100) {
                return approvalFlag ? '已通知' : '待通知'
            }
            if (approvalFlag === 0) {
                return '待審核'
            } else if (approvalFlag === 3) {
                return '同級已審'
            } else if (status === 1) {
                return '審核通過'
            } else {
                return '駁回'
            }
        },
        formatApprovalTime(approvalTime) {
            if (!approvalTime) return ''
            const date = new Date(approvalTime)
            const dateStr = date.toLocaleString()
            return dateStr.replace(/\//g, '-')
        },
        dealNext(id, nodeId) {
            if (this.dealNextLoad) return
            this.dealNextLoad = true
            acceptNextNode({
                billId: this.$route.query.id,
                nodeId: id,
                approvalId: nodeId,
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('流程確認成功！')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.dealNextLoad = false
                })
        },
        addNodes({ id }, nodeId) {
            this.approvalId = id
            this.nodeId = nodeId
            this.$router.push({
                name: 'mobileCountersign',
                query: {
                    nodeId,
                    approvalId: id,
                    id: this.$route.query.id,
                },
            })
        },
        handleOk() {
            if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.warning('存在節點沒有選擇人員！')
                return
            }
            const documentNodeParams = this.list.map((el, index) => ({
                billId: this.$route.query.id,
                sort: index,
                name: el.name,
                approvals: el.val.map((v) => ({ userId: v.userId, corpId: v.corpId })),
            }))
            acceptAddNextNode({
                documentNodeParams,
                approvalId: this.approvalId,
                nodeId: this.nodeId,
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.modelVisible = false
                })
        },
    },
}
