//
//
//
//
//
//
//

import selectLabel from '../mobileLabelManage/selectLabel.vue'
export default {
  components: {
    selectLabel
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open({ key, index }) {
      this.show = true
      this.key = key
      this.index = index
    },
    selectPersonsFromLabel(evt) {
      this.$emit('selectTodoMsg', {
        type: 'labelResult',
        data: {
          key: this.key,
          index: this.index,
          result: evt
        }
      })
      this.show = false
    }
  }
}
